<template>
    <div>
        <h6 class="text-primary font-weight-bold mb-2">
            testttttttttt
        </h6>
        <!-- draggable -->
        <draggable v-model="list" class="list-group list-group-flush cursor-move" tag="ul">
            <transition-group type="transition" name="flip-list">
                <b-list-group-item v-for="listItem in list" :key="listItem.email" tag="li">
                    <div class="d-flex">
                        <b-avatar :text="listItem.name.slice(0, 2)" />
                        <div class="ml-25">
                            <b-card-text class="font-weight-bold mb-0">
                                {{ listItem.name }}
                            </b-card-text>
                            <small>{{ listItem.email }}</small>
                        </div>
                    </div>
                </b-list-group-item>
            </transition-group>
        </draggable>

        <!-- code  -->
        <!-- <prism language="javascript" class="rounded mt-2">
            {{ list }}
        </prism> -->
    </div>
</template>

<script>

import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
    components: {
        draggable,
        Prism,
    },
    data() {
        return {
            list: [
                {
                    name: "Paz Joya",
                    email: "girliness@spotlike.co.uk",
                },
                {
                    name: "Sunshine Cose",
                    email: "executrixship@equisized.edu",
                },
                {
                    name: "Alba Dobbin",
                    email: "bidding@demibob.or",
                },
                {
                    name: "Marlin Hinchee",
                    email: "preholding@scuffly.co.uk",
                },
                {
                    name: "Leia Atienza",
                    email: "unmeasurableness@interlamellar.co.uk",
                },
                {
                    name: "Lashawna Vaudrainm",
                    email: "soaking@khubber.com",
                },
                {
                    name: "Liliana Henscheid",
                    email: "lecideine@turndown.org",
                },
                {
                    name: "Keven Kolter",
                    email: "nontenure@anglicanum.co.uk",
                },
            ],
        };
    },
};
</script>

<style>
.list-group-item {
    transition: all 1s;
}
</style>
